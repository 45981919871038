.App {
  text-align: center;
  background: url(./assets/images/Background2.png) no-repeat center fixed;
  background-size: cover;
  color: white;
}

.header {
  height: 10vh;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(rgb(0, 0, 0) 0%, rgba(255, 255, 255, 0) 100%);
}

.header button {
  border: none;
  font-family: Ubuntu;
  font-size: 2rem;
  color: white;
  background: none;
  cursor: pointer;
}

.pageButtons {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.App-link {
  color: #61dafb;
}

@media (max-width: 1000px) {

  .pageButtons {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
}

@font-face {
  font-family: "Ubuntu";
  src: local("UbuntuMono-Regular"),
    url(./assets/fonts/UbuntuMono-Regular.ttf) format("truetype");
}