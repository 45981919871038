.textBlock {
    display: inline-block;
    /*font-family: Ubuntu;*/
    font-size: 1.5rem;
    color: white;
    /*box-shadow: inset 0 0 40px 20px black;*/
    background-color: rgba(0, 0, 0, 0.74);
    border-style: solid;
    border-radius: 2%;
    border-width: 5px;
    border-color: rgb(255, 255, 255);;
    width: 66%;
    text-align: left;
    padding: 20px;
    margin-bottom: 20px;
}

.my-pic {
    float: left;
    max-height: 200px;
    max-width: 200px;
    margin: 0px 20px 0px 0px;
}

h1, h2 {
    font-family: Ubuntu;
}

p {
    margin: 0px;
}

a:link, a:visited {
    color: white;
}

.projectContainer {
    display: flex;
    align-items: center;
}

[class *= 'gameLogo'] {
    max-width: 30%;
    max-height: 25vh;
    object-fit: contain;
}

@media (max-width: 1000px) {
    .textBlock {
        justify-content: center;        
    }

    .my-pic {
        float: none;
        margin: 0px;
    }

    p {
        text-align: justify;
        font-size: .6em;
    }

    .projectContainer {
        flex-direction: column;
        align-content: center;
    }

    li {
        font-size: .6em;
    }

    h1, h2 {
        text-align: center;
    }
}