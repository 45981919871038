.container {
    position: relative;
    bottom: -500px;
    right: -350px;
}

.ocean {
    position: relative;
    height: 1000px;
    width: 1000px;
    border-radius: 50%;
    background-color: #3380B5;
    overflow: hidden;
}

[class *= 'land'] {
    position: absolute;
    height: 150px;
    width: 400px;
    border-radius: 75px;
    background-color: #85C567;
}

[class *= 'cloud'] {
    position: absolute;
    height: 60px;
    width: 250px; 
    border-radius: 30px;
    background-color: #ffffff;
}

@media (max-width: 1000px) {

    .container {
        transform: scale(0.5);
        position: relative;
        right: -300px;
    }
}